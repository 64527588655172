import React from 'react';

const Team: React.FC = () => {
  return (
    <div className="team-area default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h2>Conoce a nuestro equipo de expertos</h2>
              <div className="devider"></div>
              <p>
                Outlived no dwelling denoting in peculiar as he believed. Behaviour excellent middleton be as it curiosity departure ourselves very extreme future.
              </p>
            </div>
          </div>
        </div>
        <div className="team-items text-center">
          <div className="row">
            {/* Aquí puedes agregar los miembros del equipo */}
            <div className="single-item col-lg-4 col-md-6">
              <div className="item">
                <div className="thumb">
                  <img src="/assets/img/800x800.png" alt="Thumb" />
                  <div className="social">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                      <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div className="info">
                  <h4>Nombre del miembro</h4>
                  <span>Cargo</span>
                </div>
              </div>
            </div>
            {/* Repite este bloque para cada miembro del equipo */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;