import React from 'react';

const FunFactor: React.FC = () => {
  return (
    <div className="fun-factor-area bg-gradient text-light default-padding">
      <div className="container">
        <div className="fun-fact-items text-center">
          <div className="row">
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="timer" data-to="687" data-speed="5000">687</div>
                <span className="medium">Proyectos completados</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="timer" data-to="2348" data-speed="5000">2348</div>
                <span className="medium">Clientes satisfechos</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="timer" data-to="450" data-speed="5000">450</div>
                <span className="medium">Países cubiertos</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="timer" data-to="1200" data-speed="5000">1200</div>
                <span className="medium">Líneas de código</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunFactor;