import React from 'react';

const Subscribe: React.FC = () => {
  return (
    <div className="subscribe-area text-center text-light relative">
      <div className="half-bg-top-gray"></div>
      <div className="container">
        <div className="subscribe-items">
          <i className="flaticon-email"></i>
          <div className="fixed-shape-bottom">
            <img src="/assets/img/shape/14.png" alt="Shape" />
          </div>
          <div className="row align-center">
            <div className="col-lg-8 offset-lg-2">
              <h2>Regístrate para una prueba</h2>
              <p>
                Crea tu cuenta gratuita ahora y obtén 30 días de prueba gratuita <br /> No se requiere tarjeta de crédito
              </p>
              <form action="#">
                <input type="email" placeholder="Tu Email" className="form-control" name="email" />
                <button type="submit"> Suscribirse</button>  
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;