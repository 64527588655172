import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import Clients from './components/Clients';
import About from './components/About';
import Subscribe from './components/Subscribe';
import Features from './components/Features';
import ChooseUs from './components/ChooseUs';
import FunFactor from './components/FunFactor';
import Overview from './components/Overview';
import Team from './components/Team';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import Blog from './components/Blog';
import RegistroForm from './components/RegistroForm';
import Preloader from './components/Preloader';
import NotFound from './components/NotFound';
const Home = () => (
  <>
   <Preloader />

    <Banner />
    <Clients />
    <About />
    <Subscribe /> 
    <Features />
    <ChooseUs />
    <FunFactor />
    <Overview />
    <Team />
    <Pricing />
    <Testimonials />
    <Blog />
  </>
);

const App = () => {
  return (
    <Router>
      <div>
        <Header /> 
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/registro" element={<RegistroForm />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;