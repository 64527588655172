import React from "react";

const Banner = () => {
  return (
    <div
      className="banner-area banner-style-three text-light text-default"
      style={{ backgroundImage: "url(assets/img/shape/25.png)" }}
    >
      <div
        className="shape-left"
        style={{ backgroundImage: "url(assets/img/shape/26.png)" }}
      ></div>
      <div className="container">
        <div className="double-items">
          <div className="row align-center" style={{ marginTop: "40px" }}>
            <div className="col-lg-6 info">
              <h2 className="wow fadeInRight" data-wow-default="300ms">
                Estamos construyendo software{" "}
                <strong>para revolucionar tu negocio.</strong>
              </h2>
              <p className="wow fadeInLeft" data-wow-delay="500ms">
                Simplifica la gestión de tu negocio con{" "}
                <strong>nuestro sistema de facturación POS.</strong>
              </p>
              <a
                className="btn btn-md circle btn-light effect wow fadeInUp"
                data-wow-delay="700ms"
                href="#"
              >
                Solicita tu Demo gratis <i className="fas fa-angle-right"></i>
              </a>
            </div>
            <div
              className="col-lg-6 thumb wow fadeInRight"
              data-wow-delay="900ms"
            >
              <img src="assets/img/dashboard/18.png" alt="Thumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
