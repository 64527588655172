import React from 'react';

const Features: React.FC = () => {
  return (
    <div id="features" className="our-features-area default-padding bottom-less">
      <div className="fixed-bg" style={{backgroundImage: "url(/assets/img/shape/17.png)"}}></div>
      <div className="container">
        <div className="feature-items">
          <div className="row">
            <div className="single-item col-lg-4 col-md-6">
              <div className="item service-banner" style={{backgroundImage: "url(/assets/img/800x800.png)"}}>
                <h4>Nuestras características</h4>
                <h2>Trabaja de manera más inteligente con potentes características</h2>
                <a className="btn circle btn-theme-effect btn-sm" href="#">Ver todas las características</a>
              </div>
            </div>
            
            {/* Aquí puedes agregar más elementos de características */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;