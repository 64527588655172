import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-dark text-light">
      <div className="container">
        <div className="f-items default-padding">
          <div className="row">
            <div className="col-lg-4 col-md-6 item">
              <div className="f-item about">
                <img src="/assets/img/logo-light.png" alt="Logo" />
                <p>
                  Excellence decisively nay man yet impression for contrasted remarkably. There spoke happy for you are out. Fertile how old address did showing.
                </p>
                <form action="#">
                  <input type="email" placeholder="Tu Email" className="form-control" name="email" />
                  <button type="submit"> <i className="arrow_right"></i></button>  
                </form>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Enlace Rápido</h4>
                <ul>
                  <li>
                    <a href="index.html"><i className="fas fa-angle-right"></i> Inicio</a>
                  </li>
                  <li>
                    <a href="about-us.html"><i className="fas fa-angle-right"></i> Sobre nosotros</a>
                  </li>
                  <li>
                    <a href="about-us.html"><i className="fas fa-angle-right"></i> Historia de la compañía</a>
                  </li>
                  <li>
                    <a href="services.html"><i className="fas fa-angle-right"></i> Características</a>
                  </li>
                  <li>
                    <a href="blog-with-sidebar.html"><i className="fas fa-angle-right"></i> Página del blog</a>
                  </li>
                  <li>
                    <a href="about-us.html"><i className="fas fa-angle-right"></i> Iniciar sesión</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Comunidad</h4>
                <ul>
                  <li>
                    <a href="services-details.html"><i className="fas fa-angle-right"></i> Carrera</a>
                  </li>
                  <li>
                    <a href="services-details.html"><i className="fas fa-angle-right"></i> Liderazgo</a>
                  </li>
                  <li>
                    <a href="services-details.html"><i className="fas fa-angle-right"></i> Estrategia</a>
                  </li>
                  <li>
                    <a href="services.html"><i className="fas fa-angle-right"></i> Servicios</a>
                  </li>
                  <li>
                    <a href="about-us.html"><i className="fas fa-angle-right"></i> Historia</a>
                  </li>
                  <li>
                    <a href="services-details.html"><i className="fas fa-angle-right"></i> Componentes</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="f-item contact-widget">
                <h4 className="widget-title">Información de contacto</h4>
                <div className="address">
                  <ul>
                    <li>
                      <div className="icon">
                        <i className="fas fa-home"></i>
                      </div>
                      <div className="content">
                        <strong>Dirección:</strong>
                        Medelli, Antioquia.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="content">
                        <strong>Email:</strong>
                        <a href="mailto:info@validtheme.com">facturasoftkad@gmail.com</a>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="content">
                        <strong>Teléfono:</strong>
                        <a href="tel:2151234567">+57 300 8382624</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <p>&copy; Copyright 2024. Todos los derechos reservados por <a href="https://themeforest.net/user/validthemes/portfolio">Softkad S.A.S</a></p>
            </div>
            <div className="col-lg-6 text-end link">
              <ul>
                <li>
                  <a href="about-us.html">Términos</a>
                </li>
                <li>
                  <a href="about-us.html">Privacidad</a>
                </li>
                <li>
                  <a href="contact.html">Soporte</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;