import React from 'react';

const Testimonials: React.FC = () => {
  return (
    <div className="testimonials-area default-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h2>Lo que dicen nuestros clientes</h2>
              <div className="devider"></div>
              <p>
                Outlived no dwelling denoting in peculiar as he believed. Behaviour excellent middleton be as it curiosity departure ourselves very extreme future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="testimonial-items text-center">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="testimonials-carousel owl-carousel owl-theme">
                {/* Aquí puedes agregar los testimonios */}
                <div className="item">
                  <p>
                    "Vastly or when draw door one. While merry large of on front. Chicken quietest account ten removal country old. Attention affection at my preferred offending shameless me if agreeable. Life lain held calm and true neat she."
                  </p>
                  <div className="provider">
                    <div className="thumb">
                      <img src="/assets/img/100x100.png" alt="Thumb" />
                    </div>
                    <div className="info">
                      <h5>Nombre del Cliente</h5>
                      <span>Cargo / Empresa</span>
                    </div>
                  </div>
                </div>
                {/* Repite este bloque para cada testimonio */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;