import React from 'react';

const Clients: React.FC = () => {
  return (
    <div className="clients-style-one-area default-padding text-center">
      <div className="container">
        <div className="clients-style-one-box">
          <div className="row">
            <div className="col-lg-12">
              <h3>Over <strong>150,000+</strong> client all over the world</h3>
              <div className="partner-carousel owl-carousel owl-theme">
                <img src="/assets/img/logo/1.png" alt="Partner" />
                <img src="/assets/img/logo/2.png" alt="Partner" />
                <img src="/assets/img/logo/3.png" alt="Partner" />
                <img src="/assets/img/logo/4.png" alt="Partner" />
                <img src="/assets/img/logo/5.png" alt="Partner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;