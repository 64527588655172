import React from 'react';

const Overview: React.FC = () => {
  return (
    <div className="overview-area default-padding">
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-6 info">
            <h2>Trabaja rápidamente con una poderosa configuración</h2>
            <p>
              Compelled through a passion for quality and customer service, we strive to provide the best experience possible.
            </p>
            <ul>
              <li>Responsive Design</li>
              <li>Easy to Customize</li>
              <li>Retina Ready</li>
              <li>Unlimited Support</li>
            </ul>
            <a className="btn btn-theme-effect btn-md" href="#">Empezar ahora</a>
          </div>
          <div className="col-lg-6 thumb">
            <img src="/assets/img/illustration/5.png" alt="Thumb" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;