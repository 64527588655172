import React from 'react';

const About: React.FC = () => {
  return (
    <div id="about" className="about-area bg-gray default-padding">
      <div className="fixed-shape-left">
        <img src="/assets/img/shape/9.png" alt="Shape" />
      </div>
      <div className="container">
        <div className="about-items">
          <div className="row align-center">
            <div className="col-lg-6">
              <div className="thumb">
                <img className="wow fadeInLeft" data-wow-delay="300ms" src="/assets/img/dashboard/16.png" alt="Thumb" />
                <img className="wow fadeInUp" data-wow-delay="500ms" src="/assets/img/dashboard/11.png" alt="Thumb" />
              </div>
            </div>
            <div className="col-lg-6 info wow fadeInRight">
              <h4>Historia sobre nosotros</h4>
              <h2>Lugar perfecto para Diseño, Desarrollo, Software.</h2>
              <ul>
                <li>
                  <h5>Descarga gratuita de la aplicación</h5>
                  <p>
                    Fruit defer in party me built under first. Forbade him but savings sending ham general. So play do in near park that pain. 
                  </p>
                </li>
                <li>
                  <h5>Confiable y fiable</h5>
                  <p>
                    Fruit defer in party me built under first. Forbade him but savings sending ham general. So play do in near park that pain. 
                  </p>
                </li>
              </ul>
              <div className="button">
                <a className="btn circle btn-theme-effect btn-sm" href="#">Iniciar prueba gratuita</a>
                <a href="#">Ver todas las características</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;