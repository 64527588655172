import React from "react";
import "../components/styles/Pricing.css";

const Pricing: React.FC = () => {
  return (
    <div id="pricing" className="pricing-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center fresh">
            <h2>Nuestros Planes</h2>
            <div className="divider"></div>
            <p>Elige el plan que mejor se adapte a tus necesidades.</p>
          </div>
        </div>

        <div className="pricing-cards">
          {/* Card Paquete Independiente */}
          <div className="card">
            <h4>Paquete Independiente</h4>
            <div className="price">
              <h2>$110,000</h2>
              <span>/ Anual</span>
            </div>
            <ul>
              <li>Usuarios: 1</li>
              <li>Documentos electrónicos: 24</li>
              <li>Finanzas</li>
              <li>Ventas</li>
            </ul>
            <button>Comprar</button>
          </div>

          {/* Card Paquete Básico */}
          <div className="card">
            <h4>Paquete Básico</h4>
            <div className="price">
              <h2>$550,000</h2>
              <span>/ Anual</span>
            </div>
            <ul>
              <li>Usuarios: 1</li>
              <li>Documentos electrónicos: 1450</li>
              <li>Finanzas</li>
              <li>Punto de venta POS</li>
              <li>Ventas</li>
              <li>Reportes</li>
            </ul>
            <button>Comprar</button>
          </div>

          {/* Card Paquete Estándar */}
          <div className="card">
            <h4>Paquete Estándar</h4>
            <div className="price">
              <h2>$750,000</h2>
              <span>/ Anual</span>
            </div>
            <ul>
              <li>Usuarios: 1</li>
              <li>Documentos electrónicos: 2500</li>
              <li>Finanzas</li>
              <li>Punto de venta POS</li>
              <li>Compras</li>
              <li>Inventario</li>
              <li>Reportes</li>
              <li>Nomina Electrónica</li>
            </ul>
            <button>Comprar</button>
          </div>

          {/* Card Paquete Premium */}
          <div className="card">
            <h4>Paquete Premium</h4>
            <div className="price">
              <h2>$1,500,000</h2>
              <span>/ Anual</span>
            </div>
            <ul>
              <li>Usuarios: 2</li>
              <li>Documentos electrónicos: Ilimitados</li>
              <li>Finanzas</li>
              <li>Punto de venta POS</li>
              <li>Compras</li>
              <li>Ecommerce</li>
              <li>Inventario</li>
              <li>Reportes</li>
              <li>Nomina Electrónica</li>
              <li>APP</li>
              <li>Ventas</li>
            </ul>
            <button>Comprar</button>
          </div>

          {/* Card Plan Salud */}
          <div className="card">
            <h4>Plan Salud</h4>
            <div className="price">
              <h2>$750.000</h2>
              <span>/ Según el plan</span>
            </div>
            <ul>
              <li>Factura sector salud en todos los planes</li>
            </ul>
            <button>Comprar</button>
          </div>

          {/* Card Usuarios Adicionales */}
          <div className="card">
            <h4>Adquisición de Usuarios Adicionales</h4>
            <div className="price">
              <h2>$150,000</h2>
              <span>/ Por usuario adicional</span>
            </div>
            <ul>
              <li>Añade más usuarios a tu plan</li>
            </ul>
            <button>Comprar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
