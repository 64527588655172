import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import "./styles/RegisterForm.css"

const validationSchema = Yup.object().shape({
  tipoRazonSocial: Yup.string().required("Campo requerido"),
  razonSocial: Yup.string().required("Campo requerido"),
  ciudad: Yup.string().required("Campo requerido"),
  direccion: Yup.string().required("Campo requerido"),
  tipoRegimenIVA: Yup.string().required("Campo requerido"),
  correoContacto: Yup.string()
    .email("Correo inválido")
    .required("Campo requerido"),
  contacto: Yup.string().required("Campo requerido"),
  telefonoFijo: Yup.string(),
  telefonoMovil: Yup.string().required("Campo requerido"),
  tipoIdentificacion: Yup.string().required("Campo requerido"),
  identificacion: Yup.string().required("Campo requerido"),
  codigoActividadEconomica: Yup.string().required("Campo requerido"),
  tarifaICA: Yup.string().required("Campo requerido"),
  responsabilidadesFiscales: Yup.string().required("Campo requerido"),
  tributos: Yup.string().required("Campo requerido"),
  tipoPlanEscogido: Yup.string().required("Campo requerido"),
});

const RegistroForm = () => {
  const [mensaje, setMensaje] = useState({ texto: "", tipo: "" });

  return (
    <section className="registro-form section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center">
              <h2>Formulario de Registro</h2>
            </div>
            {mensaje.texto && (
              <div
                className={`alert ${
                  mensaje.tipo === "success" ? "alert-success" : "alert-danger"
                }`}
                role="alert"
              >
                {mensaje.texto}
              </div>
            )}
            <Formik
              initialValues={{
                tipoRazonSocial: "",
                razonSocial: "",
                ciudad: "",
                direccion: "",
                tipoRegimenIVA: "",
                correoContacto: "",
                contacto: "",
                telefonoFijo: "",
                telefonoMovil: "",
                tipoIdentificacion: "",
                identificacion: "",
                codigoActividadEconomica: "",
                tarifaICA: "",
                responsabilidadesFiscales: "",
                tributos: "",
                tipoPlanEscogido: "",
                fechaRegistro: new Date().toISOString(),
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                //enviar datos al webhook
                const webhookUrl = process.env.REACT_APP_WEBHOOK_URL;
                if (webhookUrl) {
                  fetch(webhookUrl, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json;",
                    },
                    body: JSON.stringify(values),
                  })
                    .then((response) => {
                      setMensaje({
                        texto:
                          "Registro exitoso. En breve nos pondremos en contacto con usted.",
                        tipo: "success",
                      });
                      if (!response.ok) {
                        throw new Error(
                          `HTTP error! status: ${response.status}`
                        );
                      }
                      return response.json();
                    })
                    .then((data) => resetForm())
                    .catch((error) => {
                      console.error("Error al enviar al webhook:", error);
                      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
                      setMensaje({
                        texto:
                          "Error al enviar el registro. Por favor, intente nuevamente.",
                        tipo: "error",
                      });
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                } else {
                  console.error("URL del webhook no definida");
                  setMensaje({
                    texto:
                      "Error al enviar el registro. Por favor, intente nuevamente.",
                    tipo: "error",
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="tipoRazonSocial">Tipo razón social</label>
                    <Field
                      as="select"
                      name="tipoRazonSocial"
                      className="form-control"
                    >
                      <option value="">Seleccione</option>
                      <option value="Persona Natural">Persona Natural</option>
                      <option value="Persona Juridica">Persona Jurídica</option>
                    </Field>
                    <ErrorMessage
                      name="tipoRazonSocial"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="razonSocial">Razón social</label>
                    <Field
                      type="text"
                      name="razonSocial"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="razonSocial"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="ciudad">Ciudad</label>
                    <Field type="text" name="ciudad" className="form-control" />
                    <ErrorMessage
                      name="ciudad"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="direccion">Dirección</label>
                    <Field
                      type="text"
                      name="direccion"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="direccion"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tipoRegimenIVA">Tipo de régimen IVA</label>
                    <Field
                      as="select"
                      name="tipoRegimenIVA"
                      className="form-control"
                    >
                      <option value="">Seleccione</option>
                      <option value="comun">Respondable de IVA</option>
                      <option value="simplificado">
                        No responsable de IVA
                      </option>
                      <option value="RTS">
                        Regimen Simple de Tributación RTS
                      </option>
                      <option value="ES">Empresa del Estatal</option>
                      <option value="GC">Gran contribuyente</option>
                    </Field>
                    <ErrorMessage
                      name="tipoRegimenIVA"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="correoContacto">Correo contacto</label>
                    <Field
                      type="email"
                      name="correoContacto"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="correoContacto"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="contacto">Contacto</label>
                    <Field
                      type="text"
                      name="contacto"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="contacto"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="telefonoFijo">Teléfono fijo</label>
                    <Field
                      type="text"
                      name="telefonoFijo"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="telefonoFijo"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="telefonoMovil">Teléfono móvil</label>
                    <Field
                      type="text"
                      name="telefonoMovil"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="telefonoMovil"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tipoIdentificacion">
                      Tipo Identificación
                    </label>
                    <Field
                      as="select"
                      name="tipoIdentificacion"
                      className="form-control"
                    >
                      <option value="">Seleccione</option>
                      <option value="Cedula de ciudadania">
                        Cédula Ciudadania
                      </option>
                      <option value="RC">Registro civil</option>
                      <option value="TE">Tarjeta de extranjería</option>
                      <option value="CE">Cédula de extranjería</option>
                      <option value="PS">Pasaporte</option>
                      <option value="DCI">
                        Documento de identificación extranjero
                      </option>
                      <option value="NUIP">NUIP</option>
                      <option value="PEP">
                        Permiso especial de permanencia PEP
                      </option>
                      <option value="SIDIAN">
                        Sin identificación del exterior o para uso definido por
                        la DIAN
                      </option>
                      <option value="NITOP">
                        Nit de otro país / Sin identificación del exterior
                      </option>
                      <option value="SP">Salvoconducto de permanencia</option>
                      <option value="PPT">
                        Permiso protección temporal PPT
                      </option>
                    </Field>
                    <ErrorMessage
                      name="tipoIdentificacion"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="identificacion">Digite el número de Identificación</label>
                    <Field
                      type="text"
                      name="identificacion"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="identificacion"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="codigoActividadEconomica">
                      Código actividad económica
                    </label>
                    <Field
                      type="text"
                      name="codigoActividadEconomica"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="codigoActividadEconomica"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tarifaICA">Tarifa ICA</label>
                    <Field
                      type="text"
                      name="tarifaICA"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="tarifaICA"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="responsabilidadesFiscales">
                      Responsabilidades fiscales
                    </label>
                    <Field
                      type="text"
                      name="responsabilidadesFiscales"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="responsabilidadesFiscales"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tributos">Tributos</label>
                    <Field
                      type="text"
                      name="tributos"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="tributos"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tipoPlanEscogido">
                      Tipo de plan escogido
                    </label>
                    <Field
                      as="select"
                      name="tipoPlanEscogido"
                      className="form-control"
                    >
                      <option value="">Seleccione</option>
                      <option value="Paquete independiente">
                        Paquete independiente
                      </option>
                      <option value="Paquete basico">Paquete básico</option>
                      <option value="Paquete estandar">Paquete estándar</option>
                      <option value="Paquete premium">Paquete premium</option>
                    </Field>
                    <ErrorMessage
                      name="tipoPlanEscogido"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Enviar
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistroForm;
