const Header = () => {
  return (
    <header>
      <nav className="navbar mobile-sidenav attr-border navbar-sticky navbar-default validnavs navbar-fixed white no-background">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle">
              <i className="fa fa-bars"></i>
            </button>
            <a className="navbar-brand" href="/">
              <img
                src="assets/img/logo-light.png"
                className="logo logo-display"
                alt="Logo"
              />
              <img
                src="assets/img/logo.png"
                className="logo logo-scrolled"
                alt="Logo"
              />
            </a>
          </div>
          <div className="main-nav-content">
            <div className="collapse navbar-collapse" id="navbar-menu">
              <img src="assets/img/logo.png" alt="Logo" />
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-times"></i>
              </button>
              <ul
                className="nav navbar-nav navbar-right"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Home
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="index.html">App Landing</a>
                    </li>
                    <li>
                      <a href="index-2.html">Quienes somos</a>
                    </li>
                    {/* ... más elementos del submenú ... */}
                  </ul>
                </li>
                <li>
                  <a href="/registro">Registro</a>
                </li>
              </ul>
            </div>
            <div className="overlay-screen"></div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
