import React from 'react';

const Blog: React.FC = () => {
  return (
    <div id="blog" className="blog-area default-padding bg-gray bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h2>Últimas Noticias</h2>
              <div className="devider"></div>
              <p>
                Outlived no dwelling denoting in peculiar as he believed. Behaviour excellent middleton be as it curiosity departure ourselves very extreme future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* Aquí puedes agregar las entradas del blog
                    {/* Aquí puedes agregar las entradas del blog */}
          <div className="single-item col-lg-4 col-md-6">
            <div className="item">
              <div className="thumb">
                <a href="blog-single-with-sidebar.html"><img src="/assets/img/800x600.png" alt="Thumb" /></a>
                <div className="date"><strong>05</strong> <span>Jul</span></div>
              </div>
              <div className="info">
                <div className="meta">
                  <ul>
                    <li>
                      <a href="#"><i className="fas fa-user-circle"></i> usuario</a>
                    </li>
                    <li>
                      <a href="#"><i className="fas fa-comments"></i> 12 Comentarios</a>
                    </li>
                  </ul>
                </div>
                <h4>
                  <a href="blog-single-with-sidebar.html">Superé la cría o mis preocupaciones eliminando lo indeseable.</a>
                </h4>
                <p>
                  Possession ye no mr unaffected remarkably at. Wrote house in never fruit up. Pasture imagine my garrets.  
                </p>
              </div>
            </div>
          </div>
          {/* Repite este bloque para cada entrada del blog */}
        </div>
      </div>
    </div>
  );
};

export default Blog;